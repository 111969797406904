<template>
  <div class="home">
    <h3 class="common-title">历史会话</h3>
    <div class="clearfix">
      <div class="fl pb5 pr5 pt5">
        <el-select
          v-model="chatHistoryFilter.user_id"
          placeholder="请选择用户"
          @change="handleChatUserChange"
          clearable
        >
          <el-option
            v-for="item in identifierList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="fl pb5 pr5 pt5">
        <el-input type="text" v-model="chatHistoryFilter.keyword" placeholder="请输入关键词" clearable
                  @keyup.enter="getChatHistory"></el-input>
      </div>
      <div class="fl pb5 pr5 pt5">
        <el-date-picker
          v-model="calendarValue"
          type="daterange"
          unlink-panels
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :shortcuts="shortcuts"
          size="default"
          @change="calendarChange"
        />
      </div>
      <div class="fl pb5 pr5 pt5">
        <el-button
          type="primary"
          @click="getChatHistory">
          检索
        </el-button>
      </div>
      <div class="ml10 fr">
        <el-button type="success" @click="downloadChatHistory">Excel下载</el-button>
      </div>
    </div>
    <div class="home-left">
      <el-container>
        <el-main>
          <Empty v-if="chatHistory.length === 0"></Empty>
          <el-table
            :data="chatHistory"
            class="home-list"
            style="width: 100%"
            height="calc(100vh - 600px)"
            v-else>
            <el-table-column
              prop="user.identifier"
              label="用户标识"
              min-width="200">
            </el-table-column>
            <el-table-column
              prop="query"
              label="问题"
              min-width="150">
            </el-table-column>
            <el-table-column
              prop="answer"
              label="回答"
              min-width="300">
            </el-table-column>
            <el-table-column
              prop="is_like"
              label="满意度"
              width="70">
              <template #default="scope">
                <el-icon v-if="scope.row.is_like === true" color="green">
                  <i class="fa-solid fa-thumbs-up"></i>
                </el-icon>
                <el-icon v-else-if="scope.row.is_like === false" color="red">
                  <i class="fa-solid fa-thumbs-down"></i>
                </el-icon>
              </template>
            </el-table-column>
            <el-table-column
              prop="input_token"
              label="输入Token"
              width="120">
              <template #header>
                <span>输入Token</span>
                <el-tooltip
                  class="box-item"
                  content="仅在禁用流式响应时有值"
                  placement="top"
                >
                  <el-icon class="ml5">
                    <Warning/>
                  </el-icon>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="output_token"
              label="输出Token"
              width="120">
              <template #header>
                <span>输出Token</span>
                <el-tooltip
                  class="box-item"
                  content="仅在禁用流式响应时有值"
                  placement="top"
                >
                  <el-icon class="ml5">
                    <Warning/>
                  </el-icon>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="total_cost"
              label="费用(USD)"
              width="120">
              <template #header>
                <span>费用(USD)</span>
                <el-tooltip
                  class="box-item"
                  content="仅在禁用流式响应时有值"
                  placement="top"
                >
                  <el-icon class="ml5">
                    <Warning/>
                  </el-icon>
                </el-tooltip>
              </template>
              <template #default="scope">
                <!-- 修改显示内容 -->
                <span v-if="scope.row.total_cost != null">{{ scope.row.total_cost }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="created_at"
              label="会话时间"
              :formatter="dateFormat"
              width="200">
            </el-table-column>
          </el-table>
        </el-main>
        <el-footer>
          <div class="clearfix pt20 pb20">
            <el-pagination
              class="fr"
              background
              layout="prev, pager, next, sizes, total"
              :total="chatHistoryFilter.totalSize"
              @current-change="getChatHistory"
              @size-change="getChatHistory(true)"
              v-model:current-page="chatHistoryFilter.page"
              v-model:page-size="chatHistoryFilter.size"
              :page-sizes="[10, 20, 50, 100]"
            />
          </div>
        </el-footer>
      </el-container>
    </div>

    <div class="none">
      <a ref="downloadRef"></a>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { dayjs } from 'element-plus'
import { authApi } from '@/api'
import { computed, onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {
    const route = useRoute()
    const router = useRouter()

    // merchant
    const merchant = computed(() => {
      return store.getters.merchant
    })

    const loading = ref(false)

    const serverLanguages = ref([])

    const downloadRef = ref(null)
    const download = (res) => {
      let fileName = ''
      const disposition = res.headers['content-disposition']
      if (disposition && disposition.indexOf('attachment') !== -1) {
        // 正規表現
        const filenameRegex = /filename[^;=\n]=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
          // matches[1]でとれる⇒ filename*=utf-8''201911%E3%83%87%E3%83%BC%E3%82%BF.csv;
          // 不要文字列を消して、デコードしてサーバからのファイル名を取得
          fileName = decodeURI(
            matches[1].replace(/['"]/g, '').replace('utf-8', '')
          )
        }
      }
      const fileURL = window.URL.createObjectURL(
        new Blob([res.data], { type: 'application/octet-stream' })
      )
      console.log(fileURL)
      downloadRef.value.href = fileURL
      downloadRef.value.setAttribute('download', fileName)
      downloadRef.value.click()
      window.URL.revokeObjectURL(fileURL)
    }
    // 会话记录
    const chatHistoryFilterRaw = reactive({
      user_id: null,
      page: 1,
      size: 10,
      keyword: null,
      totalSize: 0,
      start_date: null,
      end_date: null,
    })
    const chatHistoryFilter = reactive(JSON.parse(JSON.stringify(chatHistoryFilterRaw)))
    const chatHistoryInfo = reactive({
      totalSize: 0,
      loading: false,
    })
    const handleChatUserChange = (file, fileList) => {
      if (file === '') {
        chatHistoryFilter.user_id = null
      }
      getChatHistory()
    }
    const identifierList = ref([])
    const chatHistory = ref([])
    const chatHistoryDialog = ref(false)
    const openChatHistoryDialog = (item) => {
      Object.keys(chatHistoryFilterRaw).forEach(key => {
        chatHistoryFilter[key] = chatHistoryFilterRaw[key]
      })
      merchant.value = item
      getChatHistory()
      chatHistoryDialog.value = true
    }

    const getChatHistory = (reset) => {
      if (reset === true) {
        chatHistoryFilter.page = 1
      }
      // chatHistory.value = []
      chatHistoryInfo.loading = true
      authApi.getChatHistory(merchant.value.id, chatHistoryFilter).then(res => {
        chatHistory.value = res.data.list
        chatHistoryFilter.totalSize = res.data.total
        identifierList.value = res.data.user_list.map(item => {
          return {
            value: item.id,
            label: item.identifier,
          }
        })
      }).finally(() => {
        chatHistoryInfo.loading = false
      })
    }

    const calendarValue = ref(null)
    const dateFormat = (row, column, cellValue) => {
      return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
    }
    const downloadChatHistory = () => {
      authApi.downloadChatHistory(merchant.value.id, chatHistoryFilter).then(res => {
        download(res)
      })
    }

    const filterDateFormat = (date) => {
      return dayjs(date).format('YYYY-MM-DD')
    }

    const shortcuts = [
      {
        text: '最近一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: '最近一个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: '最近三个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
      {
        text: '最近半年',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
          return [start, end]
        },
      }
    ]

    const calendarChange = (val) => {
      console.log(val)
      chatHistoryFilter.start_date = filterDateFormat(val[0])
      chatHistoryFilter.end_date = filterDateFormat(val[1])
    }


    onMounted(() => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      calendarValue.value = [start, end]
      chatHistoryFilter.start_date = filterDateFormat(start)
      chatHistoryFilter.end_date = filterDateFormat(end)
      getChatHistory()
    })
    return {
      loading,
      merchant,
      downloadRef,
      chatHistory,
      chatHistoryDialog,
      openChatHistoryDialog,
      chatHistoryFilter,
      handleChatUserChange,
      identifierList,
      getChatHistory,
      chatHistoryInfo,
      dateFormat,
      downloadChatHistory,
      calendarChange,
      shortcuts,
      calendarValue,
      // 多语言
      serverLanguages,
    }
  }
}
</script>
<style scoped>
.preview-cell {
  white-space: pre-line;
}

.document-tabs .el-tabs__item {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
